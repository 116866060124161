<template>
    <div class="px-6 pb-2 pt-6 rounded bg-background-grey">
        <draggable-tree
            :key="menu.uuid"
            :indent=48
            :data="parsedItems"
            :draggable="!readOnly"
            :cross-tree="false"
            @nodeOpenChanged="handleNodeOpenChanged"
            @change="handleChange"
        >
            <div slot-scope="{ data, store }">
                <MenuTreeItem
                    v-if="!data.isDragPlaceHolder"
                    :item="data"
                    @toggle="store.toggleOpen(data)"
                    :language="language"
                    @delete="deleteItem"
                    @edit="editItem"
                    @insertAfter="insertItemAfter"
                    @insertInside="insertItemInside"
                    :readOnly="readOnly"
                />
            </div>
        </draggable-tree>
    </div>
</template>

<script>
import { DraggableTree } from '@jambonn/vue-nested-draggable';
import MenuTreeItem from "@/modules/Apps/Components/configurator/MenuTreeItem.vue";

export default {
    name: 'MenuTree',
    props: {
        menu: {
            type: Object,
            required: true
        },
        language: {
            type: Object,
            required: true
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            openedItems: [],
        }
    },
    components: {
        MenuTreeItem,
        DraggableTree,
    },
    computed: {
        parsedItems() {
            return this.menu.items.map(this.parseItem)
        }
    },
    methods: {
        parseItem(item) {
            return {
                uuid: item.uuid,
                open: this.openedItems.includes(item.uuid),
                original: item,
                children: item.submenu_items.map(this.parseItem),
                droppable: item.item_template_uuid === '69771ad4-fe34-11ed-bca0-027fea2f4c28' ||
                    item.item_template_uuid === 'f2e4ff74-fecf-11ed-aae8-02506e7ea1f4'
            }
        },
        handleNodeOpenChanged(node) {
            if (node.open) {
                this.openedItems.push(node.uuid)
            } else {
                this.openedItems = this.openedItems.filter((item) => item !== node.uuid)
            }
            this.$emit('nodeOpenChanged')
        },
        handleChange(node, targetTree) {
            let ordering = 0

            const unparseItem = function (item) {
                return {
                    ...item.original,
                    ordering: ordering,
                    submenu_items: item.children.map((subitem) => unparseItem(subitem, ++ordering))
                }
            }

            this.menu.items = targetTree.getPureData().map((item) => unparseItem(item, ++ordering))
        },
        deleteItem(item) {
            this.$emit('deleteItem', item.original)
        },
        editItem(item) {
            this.$emit('editItem', item.original)
        },
        insertItemAfter(item) {
            this.$emit('insertItemAfter', item.original)
        },
        insertItemInside(item) {
            this.$emit('insertItemInside', item.original)
        }
    },
}
</script>
